import { __ } from '@wordpress/i18n';
import { RichText } from '@wordpress/block-editor';

const BusinessCard = ({ attributes, isBackend = false, wp, custom }) => {
	const { name, title, contacts, theme, isHeaderSep } = attributes;

	return <div className={`bcbBusinessCard ${theme}`}>
		<div className='header'>
			{isBackend ? <RichText className='name' tagName='h3' value={name} onChange={val => wp.setAttributes({ name: val })} placeholder={__('Person Name', 'business-card')} inlineToolbar /> : name && <RichText.Content className='name' tagName='h3' value={name} />}

			{isBackend ? <RichText className='title' tagName='p' value={title} onChange={val => wp.setAttributes({ title: val })} placeholder={__('Person Name', 'business-card')} inlineToolbar /> : title && <RichText.Content className='title' tagName='p' value={title} />}

			{isHeaderSep && <span className='separator'></span>}
		</div>

		<div className='contacts'>
			{contacts?.length && contacts.map((contact, index) => {
				const { icon, text } = contact;

				return <div key={index} className={`contact ${isBackend && index === custom.activeIndex ? 'bcbNowEditing' : ''}`} onClick={() => isBackend && custom.setActiveIndex(index)}>
					{icon?.class && <i className={`icon ${icon?.class}`}></i>}
					<p className='text'>{text}</p>
				</div>
			})}
		</div>
	</div>
}
export default BusinessCard;