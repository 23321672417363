const Style = ({ attributes, clientId }) => {
	const { alignment, width, background, padding, border, shadow, headerAlign, headerMargin, nameTypo, nameColor, titleTypo, titleColor, headerSep, contactIconColors, contactTextTypo, contactTextColor } = attributes;

	return <style dangerouslySetInnerHTML={{
		__html: `
		${nameTypo?.googleFontLink ? `@import url(${nameTypo?.googleFontLink});` : ''}
		${titleTypo?.googleFontLink ? `@import url(${titleTypo?.googleFontLink});` : ''}
		${contactTextTypo?.googleFontLink ? `@import url(${contactTextTypo?.googleFontLink});` : ''}

		#bcbBusinessCard-${clientId}{
			text-align: ${alignment};
		}
		#bcbBusinessCard-${clientId} .bcbBusinessCard{
			width: ${'0px' === width || '0%' === width || '0em' === width ? 'auto' : width};
			${background?.styles ?? 'background-color: #0000;'}
			padding: ${padding?.styles ?? '30px 25px'};
			${border?.styles ?? ''}
			box-shadow: ${shadow?.styles ?? '0 0 10px 0 #4527a480'};
		}
		#bcbBusinessCard-${clientId} .bcbBusinessCard .header{
			justify-items: ${headerAlign};
			margin: ${headerMargin?.styles ?? '0 0 30px 0'};
		}
		#bcbBusinessCard-${clientId} .bcbBusinessCard .header .name{
			${nameTypo?.styles || 'font-size: 30px; font-weight: 700; text-transform: uppercase;'}
			color: ${nameColor};
		}
		#bcbBusinessCard-${clientId} .bcbBusinessCard .header .title{
			${titleTypo?.styles || 'font-size: 18px; font-weight: 500;'}
			color: ${titleColor};
		}
		#bcbBusinessCard-${clientId} .bcbBusinessCard .header .separator{
			${headerSep?.styles ?? 'width: 20%; border-top: 2px solid #828282;'}
		}
		#bcbBusinessCard-${clientId} .bcbBusinessCard .contacts .icon{
			${contactIconColors?.styles || 'color: #fff; background-color: #4527a4;'}
		}
		#bcbBusinessCard-${clientId} .bcbBusinessCard .contacts .text{
			${contactTextTypo?.styles || 'font-size: 15px;'}
			color: ${contactTextColor};
		}
		`.replace(/\s+/g, ' ')
	}} />;
}
export default Style;