import { render } from '@wordpress/element';

import './style.scss';
import Style from './Style';
import BusinessCard from './BusinessCard';

// Business Card
document.addEventListener('DOMContentLoaded', () => {
	const allBusinessCard = document.querySelectorAll('.wp-block-business-card');
	allBusinessCard.forEach(businessCard => {
		const attributes = JSON.parse(businessCard.dataset.attributes);

		render(<>
			<Style attributes={attributes} clientId={attributes.cId} />

			<BusinessCard attributes={attributes} />
		</>, businessCard);

		businessCard?.removeAttribute('data-attributes');
	});
});